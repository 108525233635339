// Header.js
import React from 'react';
import './Header.css';
import './Common.css';
import Logo from '../assets/logo.png';

function Header() {
  return (
    <header>
       <img src={Logo} alt="Logo" style={{height: '50px'}} /> {/* Adjust styling as needed */}
      <nav>
        <ul>
          <li><a href="#about">About</a></li>
          <li><a href="#portfolio">Portfolio</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
