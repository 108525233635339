// GrayBox.js

import React from 'react';
import './GrayBox.css';
import './Common.css'


  function GrayBox() {
    return (
      <section id="about">
        <div class="gray-box">
            <h2>ABOUT</h2>
            <h3>Introduction</h3>
            <p class="gray-container">Welcome to my website! I am Srikanth Kanteti, a seasoned IT professional with over 6 years of experience in Java/J2EE and web technologies, with a focus on Adobe Experience Manager (AEM). I am currently working as a Sr. AEM Developer at Wyndham Hotels & Resorts in Parsippany, NJ. <br/>
Throughout my career, I have worked on various AEM projects, from development to deployment and maintenance. I have a strong knowledge of developing templates, custom components, creating and launching workflows, and customizing out-of-the-box components. I am experienced in authoring pages, versioning pages, tagging content, and creating landing pages.<br/>
Prior to my current role, I worked at East West Bank, Maark LLC, and Cisco Systems, where I developed AEM custom components, restful web services, listeners, handlers, schedulers, console overlays, MSM, OSGI services, and OSGI components. I have also worked on Editable templates, Experience Fragments, and Content Fragments. I have developed custom workflows for different business use cases and configured workflow launchers to trigger workflows based on events.<br/>
Please feel free to browse my website to learn more about my skills and experience. If you have any questions or would like to discuss potential opportunities, please do not hesitate to contact me.<br/></p>
        </div>
        </section>
    );
  }

export default GrayBox;
