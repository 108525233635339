//Image-grid.js
import React from 'react';
import './ImageGrid.css';
import './Common.css';
import WyndhamLogo from '../assets/wyndham.jpeg';
import EWBLogo from '../assets/ewb.png';
import maarkLogo from '../assets/maark.jpg';
import SkillvoiceLogo from '../assets/skillvoice.png';

function ImageGrid() {
    return (
        <section id="portfolio">
    <h1 class="section-heading">PROJECTS</h1>
     <div class="grid-container">
        <div class="card fade-in">
            <a href="https://www.wyndhamhotels.com" target="_blank" rel="noreferrer">
                <img src={WyndhamLogo} alt="Wyndham-Logo" class="card-image"></img>
                </a>
                </div>
                <div class="card fade-in">
                    <a href="https://www.eastwestbank.com" target="_blank" rel="noreferrer">
                        <img src={EWBLogo} alt="EWB-Logo" class="card-image"></img>
                        </a>
                        </div>
                        <div class="card fade-in">
                            <a href="https://maark.com/" target="_blank" rel="noreferrer">
                                <img src={maarkLogo} alt="Maark-Logo" class="card-image"></img>
                                </a>
                                </div>
                                <div class="card fade-in">
                                    <a href="https://skillvoice.com/" target="_blank" rel="noreferrer">
                                        <img src={SkillvoiceLogo} alt="Sv-Logo" class="card-image"></img>
                                        </a>
                                        </div>
                                        </div>                            
      </section>
    );
}

export default ImageGrid;