//Contact.js
import React, { useState } from 'react';
import './Contact.css';
import './Common.css'

function Contact() {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    try {
        const response = await fetch('/api/contact', { // Update this URL to your Spring Boot endpoint
            method: 'POST',
            body: formData
        });

        if (response.ok) {
          setIsSubmitted(true);
        } else {
            // Handle errors
            console.log("Form submission failed");
        }
    } catch (error) {
        console.error("Error submitting form:", error);
    }
};

if (isSubmitted) {
  return <h2>Thank you for your submission!</h2>;
}


    return (
        <div>
            <section id='contact'>
      <h2>Contact Me</h2>
      <form method="post" onSubmit={handleSubmit}>
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name="name" required />

        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" required />

        <label htmlFor="subject">Subject:</label>
        <input type="text" id="subject" name="subject" required />

        <label htmlFor="message">Message:</label>
        <textarea id="message" name="message" required></textarea>

        <label htmlFor="myfile">Select files:</label>
        <input type="file" id="myfile" name="myfile" multiple /><br /><br />

        <button type="submit">Submit</button>
      </form>
      </section>
    </div>
    );
}

export default Contact;